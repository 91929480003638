import React from "react";
import ImageGallery from "./ImageGallery";
import About from "./About";
import Projects from "./Projects";

const SiteContent = () =>{

    return(
        <>
      <About></About>
       <Projects></Projects>
      </>
    )
}
export default SiteContent;