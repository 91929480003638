import React from "react";
import SiteContent from "./components/SiteContent";
import Image from "./components/Image";
import Footer from "./components/Footer";
import "./fonts.css";

const App = () => {



  return (
    <div>
      <Image></Image>
      <SiteContent></SiteContent>
      <Footer></Footer>
    </div>
    
    
  
  );
}

export default App;
